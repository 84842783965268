import { Outlet, Link, useNavigate } from 'react-router-dom'
import ModalAdmOrcamento from '../modal/ModalAdmOrcamento'
import ModalAlteraSenha from '../modal/ModalAlteraSenha'
import { useEffect, useState } from 'react'

const Layout = () => {

    const navigate = useNavigate()
    const usuario_id = sessionStorage.getItem('usuario_id')
    const perfil = sessionStorage.getItem('perfil')
    const areasFinanceirasLogin = JSON.parse(sessionStorage.getItem('areaFinanceira'))
    const [habFinanceiro, setHabFinanceiro] = useState(false)

    //console.log(perfil)

    const logout = () => {

        sessionStorage.clear()
        navigate('/')

    }

    useEffect(() => {

        //console.log(areasFinanceirasLogin.length)
        if (areasFinanceirasLogin.length > 0) {

            setHabFinanceiro(true)
            
        }

    }, [])



    return (

        <div>
          

          <body className="with-side-menu">

                <header className="site-header">
                    <div className="container-fluid">
                        <a href="#" className="site-logo">

                            <img src="pics/logoFAJ.png" alt="" className="hidden-md-down" width="200px" style={{ display: 'none' }}/>
                            <img className="hidden-lg-down" src="img/logo_FB-mob.png"  alt="" style={{ display: 'none' }} />
                        </a>

                        <button id="show-hide-sidebar-toggle" className="show-hide-sidebar">
                            <span>toggle menu</span>
                        </button>

                        <button className="hamburger hamburger--htla">
                            <span>toggle menu</span>
                        </button>
                        <div className="site-header-content">
                            <div className="site-header-content-in">
                                <div className="site-header-shown">
                                    
                                    

                                    <div className="dropdown user-menu">
                                        <button className="dropdown-toggle" id="dd-user-menu" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <img src="/img/avatar-2-64.png" alt="" />{sessionStorage.getItem('nome')}
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dd-user-menu">
                                            <a className="dropdown-item" onClick={() => window.$('.modal-AlteraSenha').modal('show')}><span className="font-icon glyphicon glyphicon-cog"></span>Alterar senha</a>
                                            <div className="dropdown-divider"></div>
                                            <a className="dropdown-item" onClick={() => logout()}><span className="font-icon glyphicon glyphicon-log-out"></span>Sair</a>
                                        </div>
                                    </div>

                                    <button type="button" className="burger-right">
                                        <i className="font-icon-menu-addl"></i>
                                    </button>
                                </div>

                                <div className="mobile-menu-right-overlay"></div>
                                <div className="site-header-collapsed">
                                    <div className="site-header-collapsed-in">
                                        <div className="dropdown dropdown-typical">
                                            <div className="dropdown-menu" aria-labelledby="dd-header-sales">
                                                <a className="dropdown-item" href="#"><span className="font-icon font-icon-home"></span>Quant and Verbal</a>
                                                <a className="dropdown-item" href="#"><span className="font-icon font-icon-cart"></span>Real Gmat Test</a>
                                                <a className="dropdown-item" href="#"><span className="font-icon font-icon-speed"></span>Prep Official App</a>
                                                <a className="dropdown-item" href="#"><span className="font-icon font-icon-users"></span>CATprer Test</a>
                                                <a className="dropdown-item" href="#"><span className="font-icon font-icon-comments"></span>Third Party Test</a>
                                            </div>
                                        </div>
                                        
                                        
                                        
                                        <div className="site-header-search-container" style={{ display: 'none'}}>
                                            <form className="site-header-search closed">
                                                <input type="text" placeholder="Search"/>
                                                <button type="submit">
                                                    <span className="font-icon-search"></span>
                                                </button>
                                                <div className="overlay"></div>
                                            </form>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="mobile-menu-left-overlay"></div>
                <nav className="side-menu">
                    <ul className="side-menu-list">
                        <li className="grey">
                            <Link to='/Inicio'>
                                <i className="font-icon font-icon-dashboard"></i>
                                <span className="lbl">Início</span>
                            </Link>
                        </li>
                      
                        
                        <li className="blue" style={{ display: perfil == 'Administrador' || perfil == 'Financeiro'|| perfil == 'Gestor' || (perfil == 'ConsultaCompras' && habFinanceiro == true) ? 'block' : 'none' }}>
                            <Link to='/Financeiro'>
                                <i className="font-icon font-icon-user"></i>
                                <span className="lbl">Financeiro</span>
                            </Link>
                        </li>

                        <li className="brown" style={{ display: perfil == 'Gestor' || perfil == 'ConsultaCompras' || perfil == 'CDOC' ? 'none' : 'block' }}>
                            <Link to='/Orcamento'>
                                <i className="font-icon font-icon-notebook"></i>
                                <span className="lbl">Orçamento </span>
                            </Link>
                            
                        </li>

                        <li className="orange-red with-sub" style={{ display: perfil == 'Administrador' ? 'none' : 'none' }}>
                            <Link to='/Planejamento'>
                                <i className="font-icon font-icon-help"></i>
                                <span className="lbl">Planejamento</span>
                            </Link>

                        </li>

                        

                        <li className="red  with-sub" style={{ display: perfil == 'Administrador' || perfil == 'CDOC' || perfil == 'Financeiro' || perfil == 'Gestor' || perfil == 'Compras' || perfil == 'ConsultaCompras' ? 'block' : 'none' }}>
                          
                            <span>
                                <i className="font-icon font-icon-contacts"></i>
                                <span className="lbl">Compras</span>
                            </span>
                            <ul>
                                <li><Link to='/Compras' style={{ display: perfil == 'Administrador' || perfil == 'Compras' || perfil == 'ConsultaCompras' || perfil == 'Financeiro' ? 'block' : 'none' }}><span className="lbl">Demandas</span></Link></li>
                                <li ><Link to='/Compras/Solicitacoes'><span className="lbl">Solicitações</span></Link></li>
                                <li style={{ display: sessionStorage.getItem('perfil') == 'Administrador' || perfil == 'Compras' || perfil == 'Gestor' ? 'block' : 'none' }}><Link to='/Compras/Calendario'><span className="lbl">Calendário</span></Link></li>
                                <li style={{ display: sessionStorage.getItem('perfil') == 'Administrador' || perfil == 'Compras' || perfil == 'Gestor' ? 'block' : 'none' }}><Link to='/Compras/Medicoes'><span className="lbl">Medições</span></Link></li>
                                
                            </ul>

                        </li>




                        <li className="blue with-sub" style={{ display: sessionStorage.getItem('perfil') == 'Administrador' || sessionStorage.getItem('perfil') == 'Gestor' ? 'block' : 'none' }}>
                            <Link to='/Downloads'>
                                <i className="font-icon font-icon-folder"></i>
                                <span className="lbl">Downloads</span>
                            </Link>

                        </li>


                        <li className="orange-red with-sub" style={{ display: sessionStorage.getItem('perfil') == 'Administrador' ? 'block' : 'none' }}>
                            <Link to='/Contratos'>
                                <i className="font-icon font-icon-help"></i>
                                <span className="lbl">Contratos</span>
                            </Link>

                        </li>



                       

                        <li className="green" style={{ display: sessionStorage.getItem('perfil') == 'Administrador' || sessionStorage.getItem('perfil') == 'Financeiro' || sessionStorage.getItem('perfil') == 'Compras' || sessionStorage.getItem('perfil') == 'Contratos' ? 'block'  : 'none' }}>
                            <Link to='/Fornecedores'>
                                <i className="font-icon font-icon-users"></i>
                                <span className="lbl">Fornecedores</span>
                            </Link>
                        </li>


                        <li className="blue-dirty" style={{ display: sessionStorage.getItem('perfil') == 'Administrador' || sessionStorage.getItem('perfil') == 'Financeiro' ? 'block' : 'none' }}>
                            <Link to='/Importacoes'>
                                <i className="font-icon font-icon-notebook"></i>
                                <span className="lbl">Importações</span>
                            </Link>
                            
                        </li>



                        <li className="aquamarine with-sub" style={{ display: sessionStorage.getItem('perfil') == 'Administrador' ? 'block' : 'none' }}>
                            <span>
                                <i className="font-icon font-icon-user"></i>
                                <span className="lbl">Administração</span>
                            </span>
                            <ul>
                                <li><Link to='/Usuarios'><span className="lbl">Usuários</span></Link></li>
                                <li><Link to='/CentroCusto'><span className="lbl">Centros de Custos</span></Link></li>
                                <li><Link to='/MateriaPrima'><span className="lbl">Matéria Prima</span></Link></li>
                                <li><Link onClick={() => window.$('.modal-AjusteOrcamento').modal('show')}><span className="lbl">Orçamento</span></Link></li>
                                <li><Link to='/FluxoCaixa'><span className="lbl">Fluxo de Caixa</span></Link></li>
                                <li><Link to='/Financeiro/Administracao'><span className="lbl">Financeiro</span></Link></li>
                                <li><Link to='/OPMEs'><span className="lbl">OPME</span></Link></li>
                                <li ><Link to='/Downloads/Administracao'><span className="lbl">Downloads</span></Link></li>
                                
                            </ul>
                        </li>


                        
                        <li className="red" style={{ display: 'none'}}>
                            <Link to='/Insalubridade'>
                                <i className="font-icon font-icon-contacts"></i>
                                <span className="lbl">Insalubridade</span>
                            </Link>
                        </li>

                        <li className="aquamarine" style={{ display: 'none'}}>
                            <Link to='/Agenda'>
                                <i className="font-icon font-icon-contacts"></i>
                                <span className="lbl">Agenda</span>
                            </Link>
                        </li>


                        <li className="black" >
                            <Link to='/'>
                                <i className="font-icon font-icon-notebook"></i>
                                <span className="lbl">Sair</span>
                            </Link>
                        </li>
                       
                       
                    </ul>

                </nav>

                <div className="page-content">
                    <div className="container-fluid">
                        <Outlet />
                    </div>
                </div>


        </body>


        <ModalAdmOrcamento />
        <ModalAlteraSenha usuario_id={usuario_id} />
            

    </div>

    


    )

}


export default Layout