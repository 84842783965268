import { useState, useMemo, useEffect } from 'react'
import api from '../components/api'
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { sleep } from '../functions/generalFunctions';
import moment from 'moment'
import LoaderIcon from "react-loader-icon";

import PedidoCompra from '../componentsPages/PedidoCompra'
import Modal from 'react-bootstrap/Modal'




const Pedidos = (props) => {

    const perfil = sessionStorage.getItem('perfil')
    const [carregando, setCarregando] = useState('block')

    const anoAtual = moment().year()
    const [ano, setAno] = useState(anoAtual.toString())
    const [listaAnos, setListaAnos] = useState()
    const [show, setShow] = useState(false);

    const [pedidoCompra_id, setPedidoCompra_id] = useState()
    const [resultado, setResultado] = useState([])

    const [solicitante, setSolicitante] = useState(perfil == 'ConsultaCompras' ? sessionStorage.getItem('solicitante') : '')
    const [resultadoSolicitantes, setResultadoSolicitantes] = useState([])

    const [resultadoSituacaoPedido, setResultadoSituacaoPedido] = useState([])
    const [pedidoCompraStatus_id, setPedidoCompraStatus_id] = useState(perfil == 'Gestor' ? 5 : perfil == 'CDOC' ? 4 : '' )
    const [urgente, setUrgente] = useState(false)


    function carregaAnos() {

        var x_array = []

        for (var x = 2024; x <= anoAtual; x++) {
            
            x_array.push(x)

        }


        setListaAnos(x_array.sort().reverse().map((ano) =>
        
            <option value={ano}>{ano}</option>
        
        ))
        

    }


    const carregaSolicitantes = () => {

      api.get('solicitantes').then((result) => {

        //console.log(result.data)

          setResultadoSolicitantes(result.data.map((rs) => 

              <option value={rs.solicitante}>{rs.solicitante}</option>
          
          ))

      }).catch((err) => {

          console.log(err.response)

      })

  }



  const carregaSituacaoPedido = () => {

    api.get('statusPedidoCompra').then((result) => {

        //console.log(result.data)

        setResultadoSituacaoPedido(result.data.map((rs) => 

            <option value={rs.pedidoCompraStatus_id}>{rs.descricao}</option>
        
        ))

    }).catch((err) => {

        console.log(err.response)

    })

}



    const columns = useMemo(
        () => [


          {
            accessorKey: 'ad_new',
            header: 'Data Cadastro',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },


          {
            accessorKey: 'numeroPedido',
            header: 'Nr Pedido',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
         
          
          {
            accessorKey: 'solicitante',
            header: 'Área solicitante',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
            
          },

         

          {
            accessorKey: 'nomeSolicitante',
            header: 'Solicitado por',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
            
          },


          {
            accessorKey: 'status',
            header: 'Situação',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
            Cell: ({ renderedCellValue, row }) => (
            
                <span class={`label label-${ renderedCellValue == 'Finalizado - Itens criados em demandas de compras' ? 'success' : renderedCellValue == 'Abertura de processo' ? 'warning' : renderedCellValue == 'Para início de demanda de compras' ? 'info' : '' }`}>{renderedCellValue}</span>
           
            ),
            
          },

          {
            accessorKey: 'urgente',
            header: 'Urgência',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
            Cell: ({ renderedCellValue, row }) => (
            
                <span class={`label label-${ renderedCellValue == true ? 'danger' : '' }`}>{renderedCellValue == true ? 'Urgente' : ''}</span>
           
            ),
            
          },

          {
            accessorKey: 'identificacao',
            header: 'Identificação',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },

          {
            accessorKey: 'dataAtendimento',
            header: 'Data Atendimento',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },

         
         
        ],
        [],
      );

    
    const resetId = (pedidoCompra_id) => {

      //console.log(pedidoCompra_id, 'aqui..')

      setPedidoCompra_id(pedidoCompra_id)

    }

    const exportaExcel = async () => {

        var dataPost = {

          solicitante: solicitante,

        }

        //console.log(dataPost)

        await api.post('pedidoCompraExtraiXls', dataPost).then((result) => {
    
          //console.log(result.data)

          if (result.data.status == 'ok') {

            

              sleep(1000).then(() => {

                  //console.log(result.data[0].fileNameCompleto, '!!')
                  //window.location.href = result.data.arquivo
                  //window.location.href = `http://192.168.255.165:3378/${result.data.arquivo}`
                  window.location.href = `${process.env.REACT_APP_API}/${result.data.arquivo}`
                  //window.location.href = `http://172.16.1.253:3378/${result.data.arquivo}`
              })
              

          }

      }).catch((err) => {

          console.log(err.response)

      })
    


    }
    


    const carregaPedidos = () => {

      var dataPost = {

        ano: ano,
        pedidoCompraStatus_id: pedidoCompraStatus_id,
        solicitante: solicitante,

      }

      setCarregando('block')
      
      api.post('pedidoCompraLista', dataPost).then((result) => {

        //console.log(result.data)

        setResultado(result.data)
        setCarregando('none')

      }).catch((err) => {

        console.log(err.response)
        setCarregando('none')

      })


    }

    
    useEffect(() => {

      
      carregaAnos()
      carregaSolicitantes()
      carregaSituacaoPedido()

    }, [])


    useEffect(() => {

      carregaPedidos()

    }, [ano, solicitante, pedidoCompraStatus_id])



    return (

        <div>



            <button type="button" class="btn btn-inline btn-primary" onClick={() => { setPedidoCompra_id();setShow(true)}} style={{ display: perfil == 'Gestor'  || perfil == 'CDOC'  ? 'none' : 'table-row'}} >+ Nova solicitação</button>
            <button type="button" class="btn btn-inline btn-success" onClick={() => exportaExcel()}  >Exportar Excel</button>
            <button type="button" class="btn btn-inline btn-info" onClick={() => carregaPedidos()}  >Atualizar</button>
            
            <br style={{ display: perfil == 'Gestor' || perfil == 'CDOC' ? 'none' : 'table-row'}} /><br style={{ display: perfil == 'Gestor' || perfil == 'CDOC' ? 'none' : 'table-row'}}/>
            


            <div className="row">


              <div className="col-md-2 col-sm-6" style={{ display: perfil == 'Administrador' || perfil == 'Compras' || perfil == 'ConsultaCompras' ? 'table-row' : 'none'}} >
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError">Ano</label>
                        <select type="text" className="form-control" value={ano} onChange={event => setAno(event.target.value)}>
                            {listaAnos}
                        </select>
                        
                    </fieldset>
                </div>
              

               <div className="col-md-5 col-sm-6" style={{ display: perfil == 'Administrador' || perfil == 'Compras' ? 'table-row' : 'none'}} >
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError">Solicitante</label>
                        <select className="form-control" value={solicitante} onChange={event => setSolicitante(event.target.value)} disabled={ perfil == 'ConsultaCompras' ? true : false} >
                            <option value="">[Selecione]</option>
                            {resultadoSolicitantes}
                        
                        </select>
                        
                    </fieldset>
                </div>

                <div className="col-md-5 col-sm-6" style={{ display: perfil == 'Administrador' || perfil == 'Compras' ? 'table-row' : 'none'}} >
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError">Situação</label>
                        <select className="form-control" value={pedidoCompraStatus_id} onChange={event => setPedidoCompraStatus_id(event.target.value)} >
                            <option value="">[Selecione]</option>
                            {resultadoSituacaoPedido}
                        
                        </select>
                        
                    </fieldset>
                </div>

                

              </div>


              <LoaderIcon  type={"cylon"} style={{ display: carregando }} />
                        
                <div style={{ display: carregando == 'none' ? 'block' : 'none'}}>
                                
                    <MaterialReactTable 
                          
                          columns={columns} 
                          data={resultado} 
                          localization={MRT_Localization_PT_BR}
                          //rowsPerPage={20}
                          //options={options}
                          initialState={{ density: 'compact' }}
                          muiTableHeadCellProps={{
                              //easier way to create media queries, no useMediaQuery hook needed.
                              sx: {
                                fontSize: {
                                  xs: '8px',
                                  sm: '9px',
                                  md: '10px',
                                  lg: '11px',
                                  xl: '12px',
                                },
                              },
                            }}
                          muiTableBodyCellProps={{
                              sx: {
                                  fontSize: {
                                    xs: '8px',
                                    sm: '9px',
                                    md: '10px',
                                    lg: '11px',
                                    xl: '12px',
                                  },
                                },
                          }}

                          muiTableBodyRowProps={({ row }) => ({
                            onClick: (event) => {
                              //console.info(event, row.original.usuario_id);
                              //navigate(`/FluxoCaixa/Vinculo/${row.original.vinculo_id}`)
                              //window.$('.modal-ComprasPedido').modal('show');setPedidoCompra_id(row.original.pedidoCompra_id)
                              setShow(true);setPedidoCompra_id(row.original.pedidoCompra_id)

                            },
                            sx: {
                              cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                            },
                          })}
                          
                      />

                </div>

                        

          <Modal
            show={show}
            
            dialogClassName="modal-fullscreen-custom" // Aplica o estilo ao dialog
            contentClassName="modal-content-custom" // Se precisar de mais personalização
            onHide={() => setShow(false)}
            autoFocus={false}            
            aria-labelledby="example-custom-modal-styling-title"
            
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                Solicitação de Compra {pedidoCompra_id} <div style={{ display: urgente == true ? 'inline-block' : 'none'}}><span className="label label-danger">URGENTE</span></div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              
                  
                  <PedidoCompra pedidoCompra_id={pedidoCompra_id} carregaPedidos={carregaPedidos} resetId={resetId} show={setShow} urgentePop={setUrgente} carregaPedidosDevolvidos={props.carregaPedidosDevolvidos} />


            </Modal.Body>
          </Modal>

          

        </div>

    )

    useEffect(() => {

      console.log(urgente)

    }, [urgente])

}

export default Pedidos