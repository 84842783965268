import { useState } from 'react'
import api from '../components/api'

const Modal = (props) => {


    const pedidoCompra_id = props.pedidoCompra_id
    const usuario_id = sessionStorage.getItem('usuario_id')

    const [motivoDevolucao, setMotivoDevolucao] = useState('')

    const salva = () => {



        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'orange',
		    title: 'Confirmação',
		    content: 'Confirma devolver o pedido?',
		    buttons: {
		        Sim: function () {

                    var dataPost = {

                        motivoDevolucao: motivoDevolucao,
                        usuario: sessionStorage.getItem('nome'),

                    }

                    //console.log

                    api.put(`pedidoCompraDevolve/${pedidoCompra_id}`, dataPost).then((result) => {

                        console.log(result.data)
                        props.show(false)
                        //window.open(`${process.env.REACT_APP_API}/${result.data.arquivo}`)
                        window.$('#modalDevolveSolicitacao').modal('hide')
                        //props.carregaPedido()
                        //props.carregaPedidos()
                        //props.totalizaDevolucoes()
                        props.carregaPedidosDevolvidos()
                        
                        //window.$('#modalPedidoCompra').modal('hide')
                        
                        window.toastr.success('Solicitação devolvida com sucesso!')
                        


                    }).catch((err) => {

                        console.log(err.response)

                    })

                    /*

                                
                    api.put(`usuarioSenha/${usuario_id}`, dataPost).then((result) => {

                        //console.log(result.data)
                        limpa()
                        window.toastr.success('Senha alterada com sucesso!')
                        window.$('.modal-AlteraSenha').modal('hide')
                        return false

                    }).catch((err) => {

                        console.log(err.response)

                    })


                    */
                
                

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });


    }

    return (

        <div>

            <div class={`modal fade modal-modalDevolveSolicitacao`}
                    id="modalDevolveSolicitacao"
                    tabindex="-1"
                    role="dialog"
                    style={{ zIndex: 1060 }}
                    
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                    
                    >
                <div class="modal-dialog modal-lg" >
                    <div class="modal-content"  >
                        <div class="modal-header" >
                            <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                                <i class="font-icon-close-2"></i>
                            </button>
                            <h4 class="modal-title" id="myModalLabel">Devolver Solicitação</h4>
                        </div>
                        <div class="modal-body">


                            <div className="row">
                                <div className="col-md-12">
                                    <fieldset className="form-group">
                                        <label className="form-label">Motivo da devolução</label>
                                        <textarea className="form-control" value={motivoDevolucao}  onChange={event => setMotivoDevolucao(event.target.value)} maxLength={1000} rows="4" />
                                    </fieldset>
                                </div>
                            
                               

                            </div>

                           

                        </div>
                        <div class="modal-footer">
                            
                            
                            <button type="button" className="btn btn-inline btn-default-outline"  data-dismiss="modal">Fechar</button>
                            <button type="button" className="btn btn-inline btn-warning-outline" onClick={() => salva()}  >Salvar devolução ao solicitante</button>
                            
                        </div>
                    </div>
                </div>
            </div>



        </div>

    )

}

export default Modal