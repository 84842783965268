import { useEffect, useState } from 'react'
import api from '../components/api'
import axios from 'axios'


const AnexosMedicao = (props) => {

    var { documentoDemanda_id, demandaStatus_id, anexos } = props


    const perfil = sessionStorage.getItem('perfil')
    const [file, setFile] = useState(null);
    const [descricaoAnexo, setDescricaoAnexo] = useState('')
    const [resultadoAnexos, setResultadoAnexos] = useState([])
    const [totalAnexos, setTotalAnexos] = useState(0)

    const handleFileChange = (event) => {
        //setFile(event.target.files[0]); 
        //console.log(event.target.files[0], 'aqui')
        setFile(event.target.files[0])
      };
    

    useEffect(() => {

        //console.log(anexos, 'aqui anexos')

        if (anexos) {

            setTotalAnexos(anexos.length)

            setResultadoAnexos(anexos.map((rs) =>
            
                <tr >

                    <td>
                        {rs.descricao}
                    </td>
                    <td style={{ width: '20%', textAlign: 'center'}}>
                        <button type="button" className="btn btn-inline btn-primary-outline btn-sm"  onClick={() => window.open(`${process.env.REACT_APP_API}/upload/demandaCompra/${rs.arquivoNome}`) }>Download</button>
                        <button style={{ display: (demandaStatus_id == 7 || demandaStatus_id == 3) ? 'none' : (perfil == 'Administrador' || perfil == 'Compras' || perfil == 'Contratos') ? 'table-row' : 'none'}} type="button" className="btn btn-inline btn-danger-outline btn-sm" onClick={() => apagaArquivo(rs.documentoAnexo_id)}>X</button>
                    </td>

                </tr>
            
            ))


        }

    }, [anexos, documentoDemanda_id])

    const apagaArquivo = (documentoAnexo_id) => {

        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'red',
		    title: 'Confirmação',
		    content: 'Confirma apagar este anexo?',
		    buttons: {
		        Sim: function () {


                                    
                        api.delete(`demandaMedicaoAnexoUpload/${documentoAnexo_id}`).then((result) => {

                            //console.log(result.data)
                            //carregaDocumentos()
                            window.toastr.success('Documento removido com sucesso!')
                            //props.carregaPedidos()
                            //props.totalizaDocumentos()
                            props.carregaDocumentos()
                            

                            return false

                        }).catch((err) => {

                            console.log(err.response)

                        })



                
                

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });

    }

    const salvaAnexo = () => {

        //console.log(documentoDemanda_id, 'aqui salvando anexo')

        if (descricaoAnexo == '' || descricaoAnexo == null) {

            window.toastr.error('<b>Descrição</b> não pode ficar em branco!')
            return false
            
        }

        if (file == null) {

            window.toastr.error('Por favor, selecione um <b>arquivo</b>')
            return false

        }

        const formData = new FormData();


        formData.append("arquivo", file);        
        formData.append("descricaoAnexo", descricaoAnexo)
        formData.append('usuario_id', sessionStorage.getItem('usuario_id'))
        //console.log(file)


        axios.post(`${process.env.REACT_APP_API}/demandaMedicaoAnexoUpload/${documentoDemanda_id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-access-token': '!nf0$@ud#2',
            }
            }).then(response => {

                //console.log(response.data)
                //window.$(".MovimentoImporta").modal('hide');
                //window.$('.modal-CompraImporta').modal('hide')
                //setImagem(response.data.imagem)
                //setFile('')
                //props.carregaAnexos()
                
                props.carregaDocumentos()
                setDescricaoAnexo('')
                window.toastr.success('Arquivo anexado com sucesso!')



            }).catch((err) => {

                console.log(err)
                console.log(err.response)

            })


    }
    

    return (


        <div className='row'>
                <div className="col-md-12">
                    <table id="table-sm" class="table table-bordered table-hover table-sm">

                        <thead>

                            <tr >
                                <th>
                                    <span class={`label label-pill label-${ totalAnexos == 0 ? 'danger' : 'primary' }`}>{totalAnexos == 0 ? 'Nenhum' : totalAnexos }</span> arquivo{ totalAnexos > 1 ? 's' : ''} anexo{ totalAnexos > 1 ? 's' : ''} 
                                </th>
                                <th>
                                    &nbsp;
                                </th>
                            </tr>

                        </thead>
                        <tbody>
                            {resultadoAnexos}
                        </tbody>

                    </table>
                    <br/>
                </div>


                <div className="col-md-12" style={{ display: perfil == 'Administrador' || perfil == 'Compras' || perfil == 'Contratos' ? 'block' : 'none' }} >
                        <fieldset className="form-group">
                            <label className="form-label">Descrição do documento</label>
                            <input type="text" className="form-control" value={descricaoAnexo} maxLength={500} onChange={event => setDescricaoAnexo(event.target.value)} disabled={ demandaStatus_id == 10 || demandaStatus_id == 11 ? false : false} />
                        </fieldset>

                    </div>

                <div className="col-md-9" style={{ display: perfil == 'Administrador' || perfil == 'Compras' || perfil == 'Contratos' ? 'block' : 'none' }}>
                    <fieldset className="form-group">
                        <label className="form-label">Arquivo</label>
                        <input type="file" className="form-control"  onChange={handleFileChange} />

                    </fieldset>
                </div>
                <div className="col-md-3" style={{ display: perfil == 'Administrador' || perfil == 'Compras' || perfil == 'Contratos' ? 'block' : 'none' }}>
                    <fieldset className="form-group">
                        <label className="form-label">&nbsp;</label>
                        <button type="button" className="btn btn-inline btn-warning-outline" onClick={() => salvaAnexo()} >Salvar anexo</button>

                    </fieldset>
                </div>

                


            

        </div>

    )

}

export default AnexosMedicao