import { useState, useMemo, useEffect } from 'react'
import api from '../components/api'
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { sleep } from '../functions/generalFunctions';
import LoaderIcon from "react-loader-icon";
import moment from 'moment'
import Modal from 'react-bootstrap/Modal'
import Medicao from '../componentsPages/Medicao';
import Compra from '../componentsPages/Compra'


const Medicoes = () => {

    const perfil = sessionStorage.getItem('perfil')
    const [carregando, setCarregando] = useState('block')

    const anoAtual = moment().year()
    const [ano, setAno] = useState(anoAtual.toString())
    const [listaAnos, setListaAnos] = useState()
    const [show, setShow] = useState(false);
    const [resultado, setResultado] = useState([])
    const [solicitante, setSolicitante] = useState('')
    const [resultadoSolicitantes, setResultadoSolicitantes] = useState()
    const [pendenteAssinatura, setPendenteAssinatura] = useState()

    const [documentoDemanda_id, setDocumentoDemanda_id] = useState()
    const [situacaoDocumento, setSituacaoDocumento] = useState('')
    
    const [demanda_id, setDemanda_id] = useState()
    const [showCompra, setShowCompra] = useState(false)

    function carregaAnos() {

        var x_array = []

        for (var x = 2024; x <= anoAtual; x++) {
            
            x_array.push(x)

        }


        setListaAnos(x_array.sort().reverse().map((ano) =>
        
            <option value={ano}>{ano}</option>
        
        ))
        

    }



    const carregaSolicitantes = () => {

        api.get('solicitantes').then((result) => {
  
          //console.log(result.data)
  
            setResultadoSolicitantes(result.data.map((rs) => 
  
                <option value={rs.solicitante}>{rs.solicitante}</option>
            
            ))
  
        }).catch((err) => {
  
            console.log(err.response)
  
        })
  
    }

    

    const columns = useMemo(
        () => [



          {
            accessorKey: 'situacaoDocumentoDescri',
            header: 'Situação',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
            Cell: ({ renderedCellValue, row }) => (
            
                <span class={`label label-${ renderedCellValue == 'Pendente Assinatura Gestor Contrato' ? 'warning' : renderedCellValue == 'Pendente Assinatura Gestão Contratos' ? 'primary' : 'success' }`}>{renderedCellValue}</span>
           
            ),
            
          },



          {
            accessorKey: 'numeroContrato',
            header: 'Contrato',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
         
          
          {
            accessorKey: 'nomeGestorContrato',
            header: 'Gestor do Contrato',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
            
          },

         

          {
            accessorKey: 'processo',
            header: 'Processo',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
            
          },



          {
            accessorKey: 'competencia',
            header: 'Competência',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },

          {
            accessorKey: 'valorNF',
            header: 'Valor NF',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },

          {
            accessorKey: 'dataEmissaoNF',
            header: 'Emissão NF',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },

          {
            accessorKey: 'dataUploadDocumento',
            header: 'Disponibilizado em',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },

          


         
        ],
        [],
      );



    const carregaMedicoes = () => {

        var dataPost = {

            situacaoDocumento: situacaoDocumento,

        }

        setCarregando('block')

        api.post('demandasMedicoes', dataPost).then((result) => {

            //console.log(result.data)

            setResultado(result.data)
            setCarregando('none')

        }).catch((err) => {

            console.log(err.response)
            setCarregando('none')

        })
        
  
    }
  
      
      useEffect(() => {
  
        
        carregaAnos()
        carregaSolicitantes()
        carregaMedicoes()
  
      }, [])
  
  
      useEffect(() => {
  
        carregaMedicoes()
  
      }, [ano, solicitante, situacaoDocumento])

    return (

        <div>


            <h5 className="m-t-lg with-border">Medições</h5>


            <div className="row">


              <div className="col-md-2 col-sm-6" style={{ display: perfil == 'Administrador' || perfil == 'Compras' || perfil == 'ConsultaCompras' ? 'table-row' : 'none'}} >
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError">Ano</label>
                        <select type="text" className="form-control" value={ano} onChange={event => setAno(event.target.value)}>
                            {listaAnos}
                        </select>
                        
                    </fieldset>
                </div>
              

               <div className="col-md-5 col-sm-6" style={{ display: perfil == 'Administrador' || perfil == 'Compras' ? 'table-row' : 'none'}} >
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError">Solicitante</label>
                        <select className="form-control" value={solicitante} onChange={event => setSolicitante(event.target.value)} disabled={ perfil == 'ConsultaCompras' ? true : false} >
                            <option value="">[Selecione]</option>
                            {resultadoSolicitantes}
                        
                        </select>
                        
                    </fieldset>
                </div>

               <div className="col-md-5 col-sm-6" style={{ display: perfil == 'Administrador' || perfil == 'Compras' ? 'table-row' : 'none'}} >
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError">Situação</label>
                        <select className="form-control" value={situacaoDocumento} onChange={event => setSituacaoDocumento(event.target.value)} disabled={ perfil == 'ConsultaCompras' ? true : false} >
                            <option value="">[Selecione]</option>
                            <option value='P'>Pendente de assinatura Gestor do Contrato</option>
                            <option value='G'>Pendente de assinatura Gestão de Contratos</option>
                            <option value='F'>Assinados</option>
                        
                        </select>
                        
                    </fieldset>
                </div>


              </div>

            <LoaderIcon  type={"cylon"} style={{ display: carregando }} />
            
            <div style={{ display: carregando == 'none' ? 'block' : 'none'}}>

              <MaterialReactTable 
                    
                    columns={columns} 
                    data={resultado} 
                    localization={MRT_Localization_PT_BR}
                    //rowsPerPage={20}
                    //options={options}
                    initialState={{ density: 'compact' }}
                    muiTableHeadCellProps={{
                        //easier way to create media queries, no useMediaQuery hook needed.
                        sx: {
                          fontSize: {
                            xs: '8px',
                            sm: '9px',
                            md: '10px',
                            lg: '11px',
                            xl: '12px',
                          },
                        },
                      }}
                    muiTableBodyCellProps={{
                        sx: {
                            fontSize: {
                              xs: '8px',
                              sm: '9px',
                              md: '10px',
                              lg: '11px',
                              xl: '12px',
                            },
                          },
                    }}

                    muiTableBodyRowProps={({ row }) => ({
                      onClick: (event) => {
                        //console.info(event, row.original.usuario_id);
                        //navigate(`/FluxoCaixa/Vinculo/${row.original.vinculo_id}`)
                        //window.$('.modal-ComprasPedido').modal('show');setPedidoCompra_id(row.original.pedidoCompra_id)
                        setShow(true);setDocumentoDemanda_id(row.original.documentoDemanda_id);setDemanda_id(row.original.demanda_id);
                        
                      },
                      sx: {
                        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                      },
                    })}
                    
                />


            </div>


            <Modal
                show={show}
                
                dialogClassName="modal-fullscreen-custom" // Aplica o estilo ao dialog
                contentClassName="modal-content-custom" // Se precisar de mais personalização
                onHide={() => setShow(false)}
                autoFocus={false}            
                aria-labelledby="example-custom-modal-styling-title"
                
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Medição {documentoDemanda_id}
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                
                    
                    <Medicao documentoDemanda_id={documentoDemanda_id} carregaMedicoes={carregaMedicoes} setShow={setShow} setShowCompra={setShowCompra} />


                </Modal.Body>
            </Modal>


            <Modal
                show={showCompra}
                dialogClassName="modal-fullscreen-custom" // Aplica o estilo ao dialog
                contentClassName="modal-content-custom" // Se precisar de mais personalização
                onHide={() => setShowCompra(false)}
                aria-labelledby="example-custom-modal-styling-title"
                
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Compra {demanda_id}
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                
                    
                    <Compra demanda_id={demanda_id} carregaDemandas={carregaMedicoes} show={setShowCompra}  />


                </Modal.Body>
            </Modal>

            

        </div>


    )


}


export default Medicoes