import { useEffect, useState, React } from "react"
import { validaCPF, fmtMoney, validaCNPJ } from "../functions/generalFunctions"
import { Link } from 'react-router-dom'
import axios from 'axios'
import api from '../components/api'
import InputMask from 'react-input-mask'
import CurrencyInput from '../components/CurrencyInput'
import ComprasAtestadoNF from "./ComprasAtestadoNF"
import moment from 'moment'

import MaskedInput from 'react-maskedinput';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { registerLocale } from  "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';
import "../components/styles.css";
import AnexosMedicao from '../componentsPages/AnexosMedicao'



const DemandaDocumentos = (props) => {

    const { demandaStatus_id, demanda_id, tipoDoc, produto, tes, numeroContrato, cpfGestorContrato, nomeGestorContrato, centroCusto_id, centroCustoDescri, itemContabil, itemContabilDescri, 
        cnpjCpf: cnpjCpfContrato, razaoSocial: razaoSocialContrato } = props

    
    const usuario_id = sessionStorage.getItem('usuario_id')
    const perfil = sessionStorage.getItem('perfil')

    const [resultadoDocumentos, setResultadoDocumentos] = useState([])

    const [descricaoAnexo, setDescricaoAnexo] = useState('')
    const [file, setFile] = useState()
   
    const [competenciaMedicao, setCompetenciaMedicao] = useState('')    

    const [valorNF, setValorNF] = useState('')
    const [dataVencimentoNF, setDataVencimentoNF] = useState('')
    const [dataEmissaoNF, setDataEmissaoNF] = useState('')
    const [numeroNF, setNumeroNF] = useState('')
    const [pedido, setPedido] = useState('')

    const [cnpjCpf, setCnpjCpf] = useState('')
    const [razaoSocial, setRazaoSocial] = useState('')

    const [resultadoGestorContrato, setResultadoGestorContrato] = useState('')
    const [nomeMarcado, setNomeMarcado] = useState(false)
    const [nomeMarcadoMedicao, setNomeMarcadoMedicao] = useState(false)

    const [nomeGestorContratoMedicao, setNomeGestorContratoMedicao] = useState('')
    const [cpfGestorContratoMedicao, setCpfGestorContratoMedicao] = useState('')

    //const [documentoDemanda_id, setDocumentoDemanda_id] = useState()


    useEffect(() => {

        if (cnpjCpfContrato) {

            setCnpjCpf(cnpjCpfContrato)

        }

    }, [cnpjCpfContrato])

    useEffect(() => {

        if (razaoSocialContrato) {

            setRazaoSocial(razaoSocialContrato)

        }        

    }, [razaoSocialContrato])

    useEffect(() => {

        if (cpfGestorContrato) {

            setCpfGestorContratoMedicao(cpfGestorContrato)
            setNomeMarcadoMedicao(true)
        }
       

    }, [cpfGestorContrato])

    useEffect(() => {

        if (nomeGestorContrato) {

            setNomeGestorContratoMedicao(nomeGestorContrato)

        }        

    }, [nomeGestorContrato])





    useEffect(() => {

        //console.log(pedidoCompra_id, pedidoCompraStatus_id)

        //if (centroCustoDescri != '' && ((pedidoCompra_id == null || pedidoCompra_id == '') && pedidoCompraStatus_id == 11)) {
        if (nomeGestorContratoMedicao != '' && nomeMarcadoMedicao == false) {

            var dataPost = {

                busca: nomeGestorContratoMedicao,

            }

            api.post('buscaGestorContrato', dataPost).then((result) => {

                //console.log(result.data)
                setResultadoGestorContrato(result.data.map((rs) =>
                
                    <Link onClick={() => {setResultadoGestorContrato('');nomeMarcadoMedicao(true);setCpfGestorContratoMedicao(rs.cpfGestorContrato);setNomeGestorContratoMedicao(rs.nomeGestorContrato)}}><font color='purple'>[{rs.cpfGestorContrato}]</font> - {rs.nomeGestorContrato}&nbsp;</Link>
                
                ))

            }).catch((err) => {

                console.log(err.response)

            })


        }


    }, [nomeGestorContratoMedicao])


    

    const salvaDocumento = () => {


        if (file == null || file == '') {


            window.toastr.error('Por favor, selecione o <b>arquivo</b> para anexar')
            return false


        } else {

            
            if (file.substr(file.length - 3).toLowerCase() != 'pdf') {

                window.toastr.error('Por favor, apenas arquivos no formato <b>PDF</b> para anexar!')
                return false


            }

        }

        

        if (descricaoAnexo == '') {

            window.toastr.error('Por favor, informe a descrição do arquivo que deseja anexar')
            return false

        }


        if (tipoDoc == 'M') {

            
            /*
            if (pedido == '') {

                window.toastr.error('<b>Pedido</b> não pode ficar em branco!')
                return false

            }
            */
            
            
            

            
            if (cnpjCpf == null) {

                window.toastr.error('<b>CNPJ</b> inválido!')
                return false

            }

            if (!validaCNPJ(cnpjCpf.replaceAll('.', '').replaceAll('-', '').replaceAll('/', ''))) {

                window.toastr.error('<b>CNPJ</b> inválido!')
                return false

            }


            if (cpfGestorContratoMedicao == null) {

                window.toastr.error('<b>CPF</b> inválido!')
                return false

            }


            if (!validaCPF(cpfGestorContratoMedicao.replaceAll('.', '').replaceAll('-', ''))) {

                window.toastr.error('<b>CPF Gestor Contrato</b> inválido!')
                return false

            }

            if (nomeGestorContratoMedicao == '' || nomeGestorContratoMedicao == null) {

                window.toastr.error('<b>Nome do gestor do contrato</b> não pode ficar em branco!')
                return false

            }

            if (razaoSocial == '' || razaoSocial == null) {

                window.toastr.error('<b>Razão Social</b> não pode ficar em branco!')
                return false

            }
            
            

            if (competenciaMedicao == '' || competenciaMedicao.indexOf('/') == -1) {

                window.toastr.error('<b>Competência</b> inválida!')
                return false

            } else {

                var competenciaMedicaoArray = competenciaMedicao.split('/')

                if (parseInt(competenciaMedicaoArray[0]) > 12) {

                    window.toastr.error('<b>Mês de competência</b> inválido!')
                    return false

                }

                if (parseInt(competenciaMedicaoArray[1]) < 2024) {

                    window.toastr.error('<b>Ano de competência</b> inválido!')
                    return false

                }

            }

            if (moment(dataEmissaoNF, "DD/MM/YYYY", true).isValid() == false) {                

                window.toastr.error('<b>Data de Emissão da NF</b> inv&aacute;lida!');
                return false;

            }

            if (moment(dataVencimentoNF, "DD/MM/YYYY", true).isValid() == false) {                

                window.toastr.error('<b>Data de Vencimento</b> inv&aacute;lida!');
                return false;

            }



        }


        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'blue',
		    title: 'Confirmação',
		    content: 'Confirma anexar o arquivo?',
		    buttons: {
		        Sim: function () {

                    

                    const formData = new FormData();

                    switch (tipoDoc) {

                        case 'D':

                            var imagefile = document.querySelector(`#fileAnexoDemandaD`);

                        break

                        case 'C':

                            var imagefile = document.querySelector(`#fileAnexoDemandaC`);

                        break

                        case 'M':
                        
                            var imagefile = document.querySelector(`#fileAnexoDemandaM`);

                        break

                    }
                    

                    

                    // 'fileimage' é o campo que o 'multer' procura o arquivo de imagem.
                    formData.append("arquivo", imagefile.files[0]);
                    formData.append("descricao", descricaoAnexo); // string junto ao formData.
                    formData.append("demanda_id", demanda_id); // string junto ao formData.
                    formData.append("ad_usr", usuario_id); // string junto ao formData.
                    formData.append("tipoDoc", tipoDoc)


                    /*
                    informações para medição
                    */

                    formData.append("competenciaMedicao", competenciaMedicao)
                    

                    formData.append("numeroNF", numeroNF)
                    formData.append("dataVencimentoNF", dataVencimentoNF)
                    formData.append("dataEmissaoNF", dataEmissaoNF)
                    formData.append("valorNF", valorNF)
                    //formData.append("pedido", pedido)                    
                    formData.append('numeroContrato', numeroContrato)
                    formData.append('cnpjCpf', cnpjCpf.replaceAll('.', '').replaceAll('-', '').replaceAll('/', ''))
                    formData.append('razaoSocial', razaoSocial)

                    formData.append('cpfGestorContrato', cpfGestorContratoMedicao.replaceAll('.', '').replaceAll('-', ''))
                    formData.append('nomeGestorContrato', nomeGestorContratoMedicao)
                    
                    //console.log(imagefile.files, tipoDoc)
                    //return false

                    axios.post(`${process.env.REACT_APP_API}/demandaDocumentoUpload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'x-access-token': '!nf0$@ud#2',
                    }
                    }).then(response => {

                        //console.log(response.data)
                        //window.$(".MovimentoImporta").modal('hide');
                        //window.$('.modal-CompraImporta').modal('hide')
                        //setImagem(response.data.imagem)
                        //setFile('')
                        //props.carregaAnexos()
                        
                        carregaDocumentos()
                        setDescricaoAnexo('')
                        setFile('')
                        props.totalizaDocumentos()
                        
                        setCompetenciaMedicao('')
                        
                    
                        setValorNF('')
                        setDataVencimentoNF('')
                        setDataEmissaoNF('')
                        setNumeroNF('')
                        setPedido('')

                        window.toastr.success('Arquivo anexado com sucesso!')

                        if (tipoDoc == 'M') {


                            window.$.confirm({
                                //icon: 'fa fa-question',
                                theme: 'dark',
                                closeIcon: true,
                                animation: 'scale',
                                type: 'orange',
                                title: 'Anexar documentos a esta medição',
                                content: 'Caso queira anexar documentos utilize o botão SALVAR ANEXO vinculado a medição na listagem abaixo.',
                                buttons: {
                                    Ok: function () {
    
                                    }                
                    
                                },
                    
                            });
                
                            
                        }




                    }).catch((err) => {

                        console.log(err)    
                        console.log(err.response)

                    })


                    
                    

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });

    }

    const apagaArquivo = (documentoDemanda_id) => {

        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'red',
		    title: 'Confirmação',
		    content: 'Confirma apagar este anexo desta solicitação?',
		    buttons: {
		        Sim: function () {


                                    
                        api.delete(`demandaDocumentoUpload/${documentoDemanda_id}`).then((result) => {

                            //console.log(result.data)
                            carregaDocumentos()
                            window.toastr.success('Documento removido com sucesso!')
                            //props.carregaPedidos()
                            props.totalizaDocumentos()
                            

                            return false

                        }).catch((err) => {

                            console.log(err.response)

                        })



                
                

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });

    }

    const carregaDocumentos = () => {


        api.get(`demandaDocumentoUpload/${demanda_id}/${tipoDoc}`).then((result) => {

            //console.log(result.data, 'aquii')
            setResultadoDocumentos(result.data.map((rs) =>

                <>
    
    
                    <tr>
                            
                        <td style={{ display: tipoDoc != 'M' ? 'block' : 'none'}}>
                            {rs.descricao}
                        </td>
                        <td >
                            <div style={{ textAlign: 'center', display: tipoDoc != 'M' ? 'block' : 'none' }}>
                                {rs.dataHoraUpload}
                                
                            </div>
                        </td>
                        <td >
                            <div style={{ textAlign: 'center', display: tipoDoc != 'M' ? 'block' : 'none' }}>
                                <button type="button" className="btn btn-inline btn-primary-outline"  onClick={() => window.open(`${process.env.REACT_APP_API}/upload/demandaCompra/${rs.arquivoNome}`) }>Download</button>
                                
                            </div>
                        </td>
                        
                        <td>
                            <div style={{ textAlign: 'center', display: tipoDoc != 'M' ? 'block' : 'none' }}>
                                <button style={{ display: (demandaStatus_id == 7 || demandaStatus_id == 3) ? 'none' : (perfil == 'Administrador' || perfil == 'Compras' || perfil == 'Contratos') ? 'block' : 'none'}} type="button" className="btn btn-inline btn-danger-outline" onClick={() => apagaArquivo(rs.documentoDemanda_id)}>X</button>
                            </div>
                        </td>
                        
                    </tr>
                    <tr style={{ display: tipoDoc == 'M' ? 'table-row' : 'none'}}>
                        <td colSpan={3}>


                            <div class="col-md-12">
                                <section class="card card-blue mb-3">
                                    <header class="card-header">
                                        
                                        <Link onClick={() => window.open(`${process.env.REACT_APP_API}/upload/demandaCompra/${rs.arquivoNome}`) }>
                                        [<font color='purple'>{rs.cnpjCpf}</font>] <font color='brown'>{rs.razaoSocial}</font><br/>
                                        {`Competência ${rs.mesCompetenciaMedicao}/${rs.anoCompetenciaMedicao}, NF ${rs.numeroNF} no valor de R$ ${rs.valorNF} ${ rs.pendenteAssinatura == false ? ` validado por ${rs.cpfAssinaturaGestorContrato} ${rs.nomeAssinaturaGestorContrato} em ${rs.dataAssinaturaGestorContrato}` : ` aguardando validação ${rs.nomeGestorContrato ? `de ${rs.nomeGestorContrato}` : ''} `}`}
                                        </Link>
                                        
                                        <button type="button" class="modal-close" style={{ display: (demandaStatus_id == 7 || demandaStatus_id == 3) ? 'none' : (perfil == 'Administrador' || perfil == 'Compras' || perfil == 'Contratos') ? 'table-row' : 'none'}} onClick={() => apagaArquivo(rs.documentoDemanda_id)}>
                                            <i className="font-icon-close-2" style={{ color: 'red'}}></i>
                                        </button>
                                    </header>
                                    <div class="card-block">
                                        <p class="card-text">

                                            <AnexosMedicao documentoDemanda_id={rs.documentoDemanda_id} carregaDocumentos={carregaDocumentos} demandaStatus_id={demandaStatus_id} anexos={rs.anexos} />

                                        </p>
                                    </div>
                                </section>
                            </div>
                        
                        </td>
                    </tr>   
                    
                
                </>

            
                
                
            
            ))


        }).catch((err) => {

            console.log(err.response)

        })


    }

    useEffect(() => {

        if (demanda_id) {

            carregaDocumentos()

        }

    }, [demanda_id])

    return (

        <div>

            { tipoDoc == 'M' && (perfil == 'Administrador' || perfil == 'Compras' || perfil == 'Contratos') ? (

                
                <ComprasAtestadoNF 
                    demanda_id={demanda_id} 
                    demandaStatus_id={demandaStatus_id} 
                    produto={produto} 
                    tes={tes} 
                    numeroContrato={numeroContrato} 
                    cpfGestorContrato={cpfGestorContrato} 
                    nomeGestorContrato={nomeGestorContrato} 
                    centroCusto_id={centroCusto_id}
                    centroCustoDescri={centroCustoDescri}
                    itemContabil={itemContabil}
                    itemContabilDescri={itemContabilDescri}
                    />


            ) : null }

            <div className="row">
                

                    <div className="col-md-12" style={{ display: perfil == 'Administrador' || perfil == 'Compras' || perfil == 'Contratos' ? 'block' : 'none' }} >
                        <fieldset className="form-group">
                            <label className="form-label">Descrição do documento</label>
                            <input type="text" className="form-control" value={descricaoAnexo} maxLength={500} onChange={event => setDescricaoAnexo(event.target.value)} disabled={ demandaStatus_id == 10 || demandaStatus_id == 11 ? false : false} />
                        </fieldset>

                    </div>

            </div>
                { tipoDoc == 'M' && (perfil == 'Administrador' || perfil == 'Compras' || perfil == 'Contratos') ? (

                        <div className="row">

                            <div className="col-md-9">

                                <fieldset className="form-group">
                                    <label className="form-label">Gestor do contrato *</label>
                                    <input type="text" className="form-control" value={nomeGestorContratoMedicao} maxLength={255} onFocus={() => {setResultadoGestorContrato();setNomeMarcadoMedicao(false)}}  onChange={event => setNomeGestorContratoMedicao(event.target.value)} disabled={ demandaStatus_id == 10 || demandaStatus_id == 11 ? false : false} />
                                    {resultadoGestorContrato}
                                </fieldset>

                                </div>

                            <div className="col-md-3" >

                                <fieldset className="form-group">
                                    <label className="form-label">CPF Gestor do contrato *</label>
                                    <InputMask mask="999.999.999-99" type="text" className="form-control" value={cpfGestorContratoMedicao} onChange={event => setCpfGestorContratoMedicao(event.target.value)} disabled={ demandaStatus_id == 10 || demandaStatus_id == 11 ? false : false} />
                                </fieldset>

                            </div>  
                                

                            <div className="col-md-4" >

                                <fieldset className="form-group">
                                    <label className="form-label">CNPJ</label>
                                    <input type="text" className="form-control" value={cnpjCpf} maxLength={25} onChange={event => setCnpjCpf(event.target.value)} disabled={ demandaStatus_id == 10 || demandaStatus_id == 11 ? false : false} />
                                </fieldset>

                            </div>
                            <div className="col-md-8" >

                                <fieldset className="form-group">
                                    <label className="form-label">Razão Social</label>
                                    <input type="text" className="form-control" value={razaoSocial} maxLength={25} onChange={event => setRazaoSocial(event.target.value)} disabled={ demandaStatus_id == 10 || demandaStatus_id == 11 ? false : false} />
                                </fieldset>

                            </div>

                            <div className="col-md-4" >

                                <fieldset className="form-group">
                                    <label className="form-label">N<sup>o</sup> da NF *</label>
                                    <input type="text" className="form-control" value={numeroNF} maxLength={25} onChange={event => setNumeroNF(event.target.value)} disabled={ demandaStatus_id == 10 || demandaStatus_id == 11 ? false : false} />
                                </fieldset>

                            </div>
                            <div className="col-md-4" >

                                <fieldset className="form-group">
                                    <label className="form-label">Valor R$ *</label>
                                    
                                    <CurrencyInput type="text" placeholder="0,00" className="form-control" value={valorNF} onChange={event => setValorNF(event.target.value)} maxLength={150} disabled={ demandaStatus_id == 10 || demandaStatus_id == 11 ? false : false}  />
                                </fieldset>

                            </div>
                            
                            
                            <div className="col-md-4" >

                                <fieldset className="form-group">
                                    <label className="form-label">Data emissão NF *</label>
                                    <DatePicker   selected={dataEmissaoNF || null} disabled={ demandaStatus_id == 10 || demandaStatus_id == 11 ? false : false} onChange={(date) => setDataEmissaoNF(date)} className="form-control" locale="ptBR" dateFormat="dd/MM/yyyy" placeHolder="DD/MM/AAAA" 
                                    customInput={ <MaskedInput mask="11/11/1111" placeholder="mm/dd/yyyy" style={{ backgroundColor: demanda_id ? '#FFF' : '#FFF'}} /> } 
                                    />
                                </fieldset>

                            </div>
                            <div className="col-md-4" style={{ display: 'none'}}>

                                <fieldset className="form-group">
                                    <label className="form-label">N<sup>o</sup> pedido *</label>
                                    <input type="text" className="form-control" value={pedido} maxLength={25} onChange={event => setPedido(event.target.value)} disabled={ demandaStatus_id == 10 || demandaStatus_id == 11 ? false : false} />
                                </fieldset>

                            </div>
                            <div className="col-md-4" >

                                <fieldset className="form-group">
                                    <label className="form-label">Data vencimento *</label>
                                    <DatePicker   selected={dataVencimentoNF || null} disabled={ demandaStatus_id == 10 || demandaStatus_id == 11 ? false : false} onChange={(date) => setDataVencimentoNF(date)} className="form-control" locale="ptBR" dateFormat="dd/MM/yyyy" placeHolder="DD/MM/AAAA" 
                                    customInput={ <MaskedInput mask="11/11/1111" placeholder="mm/dd/yyyy" style={{ backgroundColor: demanda_id ? '#FFF' : '#FFF'}} /> } 
                                    />

                                </fieldset>

                            </div>

                            <div className="col-md-4" >

                                <fieldset className="form-group">
                                    <label className="form-label">Competência *</label>
                                    <InputMask mask='99/9999' placeholder="MM/AAAA" type="text" className="form-control" value={competenciaMedicao} onChange={event => setCompetenciaMedicao(event.target.value)} disabled={ demandaStatus_id == 10 || demandaStatus_id == 11 ? false : false} />
                                </fieldset>

                            </div>

                            

                        </div>
                ) : null}
               
            <div className="row">
                
                <div className="col-md-9" style={{ display: perfil == 'Administrador' || perfil == 'Compras' || perfil == 'Contratos' ? 'block' : 'none' }}>
                    <fieldset className="form-group">
                        <label className="form-label">Arquivo</label>
                        <input type="file" className="form-control" id={ tipoDoc == 'M' ? 'fileAnexoDemandaM' : tipoDoc == 'D' ? 'fileAnexoDemandaD' : 'fileAnexoDemandaC' } value={file} onChange={event => setFile(event.target.value)} />

                    </fieldset>
                </div>
                <div className="col-md-3" style={{ display: perfil == 'Administrador' || perfil == 'Compras' || perfil == 'Contratos' ? 'block' : 'none' }}>
                    <fieldset className="form-group">
                        <label className="form-label">&nbsp;</label>
                        <button type="button" className="btn btn-inline btn-info-outline" onClick={() => salvaDocumento()} >Salvar documento</button>

                    </fieldset>
                </div>
                <div className="col-md-12" > 

                    <table id="table-sm" className="table table-hover">
                        <thead >
                                <tr style={{ textAlign: 'center', fontSize: '11px', backgroundColor: '#EFEFEF', display: tipoDoc == 'M' ? 'none' : 'table-row'}} >
                                    
                                    <th >
                                        
                                    Descrição 

                                    </th>
                                    <th >
                                        
                                    Incluso em 

                                    </th>
                                        
                                    <th colspan="2">
                                        
                                    &nbsp;                                 

                                    </th>
                                
                                </tr>
                                
                        </thead>
                        {resultadoDocumentos}
                        
                    </table>

                </div>
                
            </div>

            


        </div>

    )

}


export default DemandaDocumentos